import React from "react";

function HomeworkStepper({ activeStep, steps, setActiveStep }) {
  return (
    <ol className="space-y-4 mb-4 sm:mb-5">
      {steps.map((step) => (
        <li
          key={step}
          className="flex  flex-col items-center w-full mb-4 cursor-pointer"
          onClick={() => setActiveStep(step)}
        >
          <div
            className={`flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${
              activeStep === step
                ? " bg-[#439F46] "
                : " bg-[#ffffff]  shadow-md"
            }`}
          >
            <div
              className={`w-7 h-7  lg:w-6 lg:h-6 text-center ${
                activeStep === step ? "text-white" : "text-success"
              }`}
            >
              {step}
            </div>
          </div>
          {step !== 3 && (
            <div
              className={`w-px h-12 ${
                activeStep === step
                  ? "border-l-2 border-[#439F46]"
                  : "border-l-2 border-[#439F46] opacity-20"
              }`}
            ></div>
          )}
        </li>
      ))}
      ,
    </ol>
  );
}

export default HomeworkStepper;
