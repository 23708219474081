import React from "react";
import { useSelector } from "react-redux";

function ClientDashboard() {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <>
      <div>
        <h1 className="text-center">Client dashboard</h1>
        <h2 className="mt-5 text-3xl font-semibold text-center">
          Welcome {currentUser.fullname}
        </h2>
      </div>
    </>
  );
}

export default ClientDashboard;
