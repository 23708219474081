import React from "react";

function Balance() {
  return (
    <div className="relative p-3 flex items center space-x-3">
      <span className="text-sm hidden lg:block  "> Balance</span>
      <div className=" font-semibold border rounded-lg border-[#F4F4F4]">
        <span className="p-3">$100.00 USD</span>
      </div>
    </div>
  );
}

export default Balance;
