import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ClientDashboard from "./pages/client/dashboard";
import PrivateRoute from "./components/Auth/PrivateRoute";
import WriterDashboard from "./pages/writer/dashboard";
import AdminDashboard from "./pages/admin/dashboard";
import Home from "./pages/home";
import SelectServiceType from "./pages/client/services/SelectServiceType";
import LogInPage from "./pages/auth/LogInPage";
import Defaultlayout from "./components/Layout/Defaultlayout";
import Loader from "./commons/Loader";
import PageTitle from "./components/Page_Title";
import HomeworkHelp from "./pages/client/services/HomeworkHelp/index..jsx";
import Signup from "./pages/auth/Signup.jsx";
import SignupFreelancer from "./pages/auth/Signup_Freelance.jsx";
import EditingAndProofReadingService from "./pages/client/services/EditingAndProofReading/EditingAndProofReadingService.jsx";
import ResumeWritingPackage from "./pages/client/services/ResumeWritingPackage/ResumeWritingPackage.jsx";
const App = () => {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/auth" element={<Signup />} />
      <Route path="/signup" element={<SignupFreelancer />} />
      <Route path="/login" element={<LogInPage />} />

      <Route element={<PrivateRoute />}>
        <Route path="/selectservices" element={<SelectServiceType />} />
        <Route path="/homeworkhelpservice" element={<HomeworkHelp />} />
        <Route
          path="/proofreadingservice"
          element={<EditingAndProofReadingService />}
        />
        <Route
          path="/resumewritingpackage"
          element={<ResumeWritingPackage />}
        />
        <Route element={<Defaultlayout />}>
          <Route
            path="/dashboard"
            element={
              <>
                <PageTitle title="Hubproof | Home |Client Dashboard" />
                <ClientDashboard />
              </>
            }
          />
          <Route path="/writer" element={<WriterDashboard />} />
          <Route path="/admin" element={<AdminDashboard />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
