import React from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../../../../config";
import { useNavigate } from "react-router-dom";

function HomeworkStep3({ formData }) {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting homework brief:", formData);

    try {
      const res = await fetch(`${baseURL}/service/homework/new`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, userRef: currentUser.user._id }),
      });

      console.log(currentUser.user);

      const data = await res.json();

      console.log("Backend Response: ", data);
      if (data.success === false) {
        console.log("Error uploading data:", data.message);
      }

      navigate("/dashboard");
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h4 className="text-center font-serif">Reviewing your brief</h4>
      <button
        type="submit"
        className="green-button mx-auto w-full flex justify-center items-center text-center"
      >
        Submit
      </button>
    </form>
  );
}

export default HomeworkStep3;
