import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logos/logo.svg";
import DropdownUser from "./dropdownUser";
import DropdownMessage from "./dropdownMessage";
import DropDownEmail from "./dropDownEmail";
import Balance from "./balance";
function Header({ sidebarOpen, setSidebarOpen }) {
  return (
    <header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 ">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              setSidebarOpen(!sidebarOpen);
            }}
            className="z-99999 block rounded-md border border-stroke bg-black p-1.5 shadow-sm  lg:hidden"
          >
            <span className="relative block h-5.5 w-5.5 cursor-pointer">
              <span className="du-block absolute right-0 h-full w-full">
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-white delay-[0] duration-200 ease-in-out dark:bg-white ${
                    !sidebarOpen && "!w-full delay-300"
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-white delay-150 duration-200 ease-in-out dark:bg-white ${
                    !sidebarOpen && "delay-400 !w-full"
                  }`}
                ></span>
                <span
                  className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-white delay-200 duration-200 ease-in-out dark:bg-white ${
                    !sidebarOpen && "!w-full delay-500"
                  }`}
                ></span>
              </span>
              <span className="absolute right-0 h-full w-full rotate-45">
                <span
                  className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
                    !sidebarOpen && "!h-0 !delay-[0]"
                  }`}
                ></span>
                <span
                  className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
                    !sidebarOpen && "!h-0 !delay-200"
                  }`}
                ></span>
              </span>
            </span>
          </button>
          <Link className="block flex-shrink-0 lg:hidden" to="/dashboard">
            <img className="w-25 h-25" src={logo} alt="Logo" />
          </Link>
        </div>
        <div></div>
        <div className="flex justify-end gap-3 2xsm:gap-7">
          <Balance />
          <DropDownEmail />
          <DropdownMessage />
          <ul className="flex items-center gap-2 2xsm:gap-4">
            <DropdownUser />
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
