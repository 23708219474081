import React from "react";
import subject from "../../../../../assets/svg_icons/subject.svg";
import topic from "../../../../../assets/svg_icons/newMessage.svg";
import upload from "../../../../../assets/svg_icons/upload.svg";
import arrowdown from "../../../../../assets/svg_icons/arrow_down.svg";
import slides from "../../../../../assets/svg_icons/note.svg";
import charts from "../../../../../assets/svg_icons/graph.svg";

function HomeworkStep1({
  handleNextStep,
  formData,
  handleFileChange,
  handleInputChange,
  fileNames,
  uploadProgress,
}) {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";

  return (
    <form className="w-full lg:px-5">
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Subject</label>
          <div className="relative">
            <input
              id="subject"
              type="text"
              placeholder="Enter subject here"
              required
              className={inputClassValues}
              value={formData.subject}
              onChange={handleInputChange}
            />
            <img
              src={subject}
              alt="subject"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Topic</label>
          <div className="relative">
            <input
              id="topic"
              type="text"
              placeholder="Enter topic here"
              required
              className={inputClassValues}
              value={formData.topic}
              onChange={handleInputChange}
            />
            <img
              src={topic}
              alt="topic"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-3 mt-4">
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Project details</label>
          <textarea
            id="details"
            rows="4"
            placeholder="Write project details here"
            className={inputClassValues}
            value={formData.details}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Attach files</label>
          <div className="relative">
            <input
              type="file"
              onChange={handleFileChange}
              multiple
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
            <div className="flex flex-col items-center justify-center border-2 border-[#b2f3a7] border-dotted w-full bg-[#f5f5f5] p-6 rounded-lg">
              <div className="rounded-full bg-white shadow-md w-10 h-10 flex items-center justify-center">
                <img src={upload} alt="upload" className="w-4 h-4" />
              </div>
              <p className="text-center mb-3 text-gray-900 text-sm">
                Upload files
              </p>
              {fileNames.length > 0 && (
                <ul className="mt-4 w-full list-disc pl-5 text-sm text-gray-500">
                  {fileNames.map((name, index) => (
                    <li key={index}>
                      {name}{" "}
                      {uploadProgress[name] !== undefined &&
                        `(${uploadProgress[name]}%)`}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <label className="text-sm text-gray-800">Page count</label>
        <div className="relative">
          <input
            id="pageCount"
            type="number"
            className={inputClassValues}
            value={formData.pageCount}
            onChange={handleInputChange}
          />
          <img
            src={arrowdown}
            alt="arrow"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-3 mt-4">
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">PowerPoint slides</label>
          <div className="relative">
            <input
              id="powerPointSlides"
              type="number"
              placeholder="Number of slides"
              className={inputClassValues}
              value={formData.powerPointSlides}
              onChange={handleInputChange}
            />
            <img
              src={slides}
              alt="slides"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Charts and graphs</label>
          <div className="relative">
            <input
              id="noOfCharts"
              type="number"
              placeholder="Number of charts/graphs"
              className={inputClassValues}
              value={formData.noOfCharts}
              onChange={handleInputChange}
            />
            <img
              src={charts}
              alt="charts"
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            />
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-3 mt-4">
        <div className="w-full lg:w-1/2 mt-2">
          <label className="text-sm text-gray-800">Paper format</label>
          <div className="relative w-full">
            <select
              className={inputClassValues}
              id="format"
              value={formData.format}
              onChange={handleInputChange}
              required
            >
              <option value="MLA">MLA</option>
              <option value="APA">APA</option>
              <option value="Chicago">Chicago</option>
              <option value="Harvard">Harvard</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className="w-full lg:w-1/2 mt-2">
          <label className="text-sm text-gray-800">Sources count</label>
          <div className="relative w-full">
            <input
              className={inputClassValues}
              type="text"
              placeholder="Sources count"
              id="noOfSources"
              value={formData.noOfSources}
              onChange={handleInputChange}
              required
            />
            <img
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-4 h-4"
              src={arrowdown}
              alt="arrow"
            />
          </div>
        </div>
      </div>

      <button
        type="button"
        onClick={handleNextStep}
        className="green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
      >
        Next
      </button>
    </form>
  );
}

export default HomeworkStep1;
