import React from "react";
import tick from "../../../../../assets/svg_icons/green-tick.svg";
import rightArrow from "../../../../../assets/svg_icons/arrow-left.svg";

function Step1() {
  const inputClassValues =
    "w-full bg-[#f5f5f5] text-gray-900 mt-2 p-3 rounded-lg border border-[#D7E8D8] focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-[#2BC210]";

  const items = ["item1", "item2", "item3", "item4"];
  const secondList = [
    "Source file",
    "Editing file",
    "Customer design",
    "Review & favorite",
    "Unlimited revisions",
  ];
  const thirdList = [
    "Preventing of spacing",
    "The original",
    "Preventing of spacing",
    "The original",
  ];
  return (
    <form className="w-full 2xl:px-5">
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Choose level</label>
          <div className="relative">
            <input
              id="level"
              type="text"
              placeholder=" level"
              required
              className={inputClassValues}
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <label className="text-sm text-gray-800">Enter deadline</label>
          <div className="relative">
            <input
              id="deadline"
              className={inputClassValues}
              type="datetime-local"
              placeholder="Enter Deadline"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 mt-4 gap-5">
        {/* Package */}
        <div className="shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-default transition-transform duration-500 transform hover:-translate-y-1">
          <div className="flex  flex-row items-center">
            <div>
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-3  text-md">
                Basic
              </label>
            </div>
            <div className="ml-5">
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-5 text-md">
                Standard
              </label>
            </div>
            <div className="ml-5">
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-3  text-md">
                Premium
              </label>
            </div>
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
          <div className="font-semibold text-md"> Structure check</div>
          <div className="text-left text-sm mt-5 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </div>
          <div className="grid grid-cols-1 ">
            {items.map((item, index) => (
              <div className="flex items-center" key={index}>
                <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
                <span className="text-sm">{item}</span>
              </div>
            ))}
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
          <div className="flex flex-row justify-between">
            <p className="text-md font-semibold">Price </p>
            <p className="text-lg text-[#2BC210] font-extrabold"> $90</p>
          </div>
          <div className="mt-8  ms-0 text-[12px] text-left text-graydark font-bold"></div>
        </div>
        {/* Amount to pay */}
        <div>
          <div className="flex flex-row justify-between">
            <p className="text-lg font-bold ml-5">Amount to pay </p>
            <p className="text-lg text-[#2BC210] font-extrabold"> $199</p>
          </div>
          <div className="bg-[#F7FBF7]  shadow-md px-8 py-3 rounded-[15px] border border-[#E6F1E6] mt-5">
            <div className="grid grid-cols-1 mb-8 ">
              {secondList.map((item, index) => (
                <div className="flex items-center mt-3 font-bold" key={index}>
                  <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
                  <span className="text-md">{item}</span>
                </div>
              ))}
            </div>
            <button className="flex  justify-center w-full items-center px-5 py-3 border  border-[#6DBC70] bg-[#FFFFFF] rounded-[10px]  shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-pointer transition-transform duration-500 transform hover:scale-105 ">
              Review your brief
              <span>
                <img src={rightArrow} alt="arrow" className="ml-2" />
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* Standard Add on services */}
      <div className="text-lg font-bold ml-5 mt-6 mb-3">
        Standard add on services
      </div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-default transition-transform duration-500 transform hover:-translate-y-1">
          <div className="flex  flex-row items-center">
            <div>
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-3  text-md">
                Cover letter
              </label>
            </div>
            <div className="ml-5">
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-5 text-md">
                Linked in
              </label>
            </div>
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>

          <div className="grid grid-cols-1 ">
            {thirdList.map((item, index) => (
              <div className="flex items-center" key={index}>
                <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
                <span className="text-sm">{item}</span>
              </div>
            ))}
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
          <div className="flex flex-row justify-between">
            <p className="text-md font-semibold">Price </p>
            <p className="text-lg text-[#2BC210] font-extrabold"> $99</p>
          </div>
          <div className="mt-8  ms-0 text-[12px] text-left text-graydark font-bold"></div>
        </div>
      </div>
      {/* Standard Add ons*/}
      <div className="text-lg font-bold ml-5 mt-6 mb-3">Standard addons</div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-5">
        <div className="shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-default transition-transform duration-500 transform hover:-translate-y-1">
          <div className="flex  flex-row items-center">
            <div>
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-3  text-md">
                Cover letter
              </label>
            </div>
            <div className="ml-5">
              <input
                type="checkbox"
                className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
              />
              <label htmlFor="custom-checkbox" className="ml-5 text-md">
                Linked in
              </label>
            </div>
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>

          <div className="grid grid-cols-1 ">
            {thirdList.map((item, index) => (
              <div className="flex items-center" key={index}>
                <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
                <span className="text-sm">{item}</span>
              </div>
            ))}
          </div>
          <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
          <div>
            <input
              type="checkbox"
              className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
            />
            <label htmlFor="custom-checkbox" className="ml-3  text-sm">
              (150 words ) $99
            </label>
          </div>
          <div className="mt-4">
            <input
              type="checkbox"
              className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
            />
            <label htmlFor="custom-checkbox" className="ml-3 text-sm">
              (300 words ) $199
            </label>
          </div>
          <div className="mt-8  ms-0 text-[12px] text-left text-graydark font-bold"></div>
        </div>
      </div>
    </form>
  );
}

export default Step1;
