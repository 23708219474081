import React from "react";
import tick from "../../../../../assets/svg_icons/green-tick.svg";
import StructureCheckCard from "../../../../../components/StructureCheckCard";

function Step1() {
  const packageAddonsList = [
    {
      title: "Structure check",
      itemsList: ["item1", "item2", "item3", "item4"],
    },
    {
      title: "Structure check",
      itemsList: ["item1", "item2", "item3", "item4"],
    },
    {
      title: "Structure check",
      itemsList: ["item1", "item2", "item3", "item4"],
    },
  ];
  return (
    <form className="w-full lg:px-5">
      <div className="flex justify-between items-center sm:gap-5 mb-5">
        <label className="text-lg text-gray-900 font-semibold text-left">
          Editing & Proofreading
        </label>
        <div className="bg-[#F5FFF6] shadow-md px-8 py-3 rounded-[20px] border border-[#2BC210] text-2xl text-[#2BC210] font-extrabold">
          $49
        </div>
      </div>
      <div className="bg-[#f5f5f5] shadow-[#D4DFD41F] px-9 py-5 rounded-[10px] border border-[#E4EFE4]">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
          <div className="flex items-center">
            <img src={tick} className="w-4 h-4 mr-3" alt="bullet" />
            <span className="text-sm">Item 1</span>
          </div>
          <div className="flex items-center">
            <img src={tick} className="w-4 h-4 mr-3" alt="bullet" />
            <span className="text-sm">Item 2</span>
          </div>
          <div className="flex items-center">
            <img src={tick} className="w-4 h-4 mr-3" alt="bullet" />
            <span className="text-sm">Item 3</span>
          </div>
          <div className="flex items-center">
            <img src={tick} className="w-4 h-4 mr-3" alt="bullet" />
            <span className="text-sm">Item 4</span>
          </div>
          <div className="flex items-center">
            <img src={tick} className="w-4 h-4 mr-3" alt="bullet" />
            <span className="text-sm">Item 5</span>
          </div>
          <div className="flex items-center">
            <img src={tick} className="w-4 h-4 mr-3" alt="bullet" />
            <span className="text-sm">Item 6</span>
          </div>
        </div>
        <div className="mt-8 text-sm text-graydark font-bold">
          <p> * Price : $25 per 100 words </p>{" "}
        </div>
      </div>
      {/* popular addons */}
      <div className="mt-8">
        <label className="text-lg text-gray-900 font-semibold text-left ">
          Popular addons
        </label>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-5">
        {packageAddonsList.map((addon, index) => (
          <StructureCheckCard
            key={index}
            cardLabel={addon.title}
            items={addon.itemsList}
          />
        ))}
      </div>
      <div className="mt-8  mb-3">
        <button
          type="submit"
          className=" green-button mx-auto lg:w-1/4 sm:w-3/4 w-1/2  p-8 flex justify-center items-center text-center"
        >
          Review your brief
        </button>
      </div>
    </form>
  );
}

export default Step1;
