import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logos/hubproof.png";
import OrderManagementCard from "../Cards/OrderManagementNav";
import Tools from "../Cards/Tools";
import Discounts from "../Cards/DiscountsNav";
import CoursesNav from "../Cards/CoursesNav";
import FinanceNav from "../Cards/FinanceNav";
import LearnNav from "../Cards/LearnNav";
import HubproofNav from "../Cards/HubproofNav";
import TermsNav from "../Cards/TermsNav";

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSisebarExpanded = localStorage.getItem("sidebarExpanded");
  const [sidebarExpanded] = useState(
    storedSisebarExpanded === null ? false : storedSisebarExpanded === "true"
  );

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  });
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => {
      document.removeEventListener("keydown", keyHandler);
    };
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen w-90 flex-col overflow-y-scroll scroll-wrapper  bg-white duration-300 ease-linear  lg:static lg:translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex items-center justify-between gap-2 px-6 py-0 bg-white ">
        <NavLink to={"/dashboard"}>
          <img className="h-22 w-auto" src={logo} alt="logo" />
        </NavLink>
        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>

      <div className="w-full max-w-sm bg-white border border-[#F4F4F4] rounded-lg shadow p-2 ">
        <Link
          to={"/selectservices"}
          className="border border-black rounded-lg  flex  justify-center space-x-1 text-black font-bold p-2 "
        >
          <span className="">+</span>
          <span className="">Place An order</span>
        </Link>
        <OrderManagementCard />
        <Tools />
        <Discounts />
        <CoursesNav />
        <FinanceNav />
        <LearnNav />
        <HubproofNav />
        <TermsNav />
      </div>
    </aside>
  );
};

export default Sidebar;
