import React, { useState } from "react";
import { Link } from "react-router-dom";
import HomeworkStepper from "../HomeworkHelp/HomeworkStepper";
import backArrow from "../../../../assets/svg_icons/arrow_left_1.svg";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";

function EditingAndProofReadingService() {
  const [activeStep, setActiveStep] = useState(1);
  return (
    <div className="container mx-auto bg-[#f5f5f5] p-8">
      <div className="flex flex-row justify-start items-center mb-8">
        <img src={backArrow} alt="Back" className="w-6 mr-3" />
        <Link to="/selectservices" className="text-green-600">
          Back
        </Link>
      </div>

      <div className="flex justify-center mb-8">
        {[1, 2, 3].map((step) => (
          <div
            key={step}
            className={`w-20 h-2 bg-[#439F46] rounded-full mr-2 cursor-pointer ${
              activeStep !== step ? "opacity-20" : ""
            }`}
            onClick={() => setActiveStep(step)}
          />
        ))}
      </div>

      <div className="text-center mb-8">
        <h1>Choose A Proofreading & Editing Package</h1>
      </div>

      <div className="flex w-full min-h-screen">
        <div className="hidden lg:block lg:w-1/4 bg-[#F7FBF7] p-8 rounded-[40px] shadow-md h-90 mt-12">
          <HomeworkStepper
            activeStep={activeStep}
            steps={[1, 2, 3]}
            setActiveStep={setActiveStep}
          />
        </div>

        <div className="lg:w-3/4 w-full p-8">
          {activeStep === 1 && <Step1 />}
          {activeStep === 2 && <Step2 />}
          {activeStep === 3 && <Step3 />}
        </div>
      </div>
    </div>
  );
}

export default EditingAndProofReadingService;
