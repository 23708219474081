import React from "react";
import tick from "../assets/svg_icons/green-tick.svg";

function StructureCheckCard({ cardLabel, items }) {
  return (
    <div className="shadow-[#D4DFD41F] bg-[#f5f5f5] border border-[#E4EFE4] p-6 rounded-[10px] shadow-[2px_3px_28px_rgba(212,223,212,0.12)]  cursor-default transition-transhtmlForm duration-500 transhtmlForm hover:-translate-y-1">
      <div className="flex items-center">
        <input
          type="checkbox"
          className="appearance-none checked:appearance-auto h-5 w-5 bg-white border border-[#E4EFE4] rounded shadow-md  checked:bg-[#2BC210] focus:outline-[#E4EFE4]  focus:ring-[#2BC210]"
        />
        <label htmlFor="custom-checkbox" className="ml-5 font-semibold text-sm">
          {cardLabel}
        </label>
      </div>
      <div className="border-[1px] border-[#E6F1E6] w-full mx-auto mt-3 mb-5"></div>
      <div className="grid grid-cols-1 ">
        {items.map((item, index) => (
          <div className="flex items-center" key={index}>
            <img src={tick} className="w-4 h-4 mr-4 mb-2" alt="bullet" />
            <span className="text-sm">{item}</span>
          </div>
        ))}
      </div>
      <div className="mt-8  ms-0 text-[12px] text-left text-graydark font-bold">
        <p>* starting from $3 pg / $ 0.0109 / word </p>
      </div>
    </div>
  );
}

export default StructureCheckCard;
