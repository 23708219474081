import React from "react";
import ServiceType from "../../../components/ServiceType/ServiceType";
import homeworkhelp from "../../../assets/svg_icons/homework.svg";
import proofreading from "../../../assets/svg_icons/proofreading.svg";
import resumewriting from "../../../assets/svg_icons/resumewriting.svg";
import citationediting from "../../../assets/svg_icons/citationediting.svg";
import plagiarism from "../../../assets/svg_icons/plagiarism.svg";
import backArrow from "../../../assets/svg_icons/arrow_left_1.svg";
import { Link } from "react-router-dom";

function SelectServiceType() {
  //list of service types
  const serviceTypeLists = [
    {
      image: homeworkhelp,
      title: "Homework help",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      route: "/homeworkhelpservice",
    },
    {
      image: proofreading,
      title: "Editing & Proofreading",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      route: "/proofreadingservice",
    },
    {
      image: resumewriting,
      title: "Resume Writing",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      route: "/resumewritingpackage",
    },
    {
      image: citationediting,
      title: "Citation Editing",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
      route: "/",
    },
  ];

  return (
    <div>
      <div className="flex flex-row justify-start items-center mb-4 mt-5 ms-6">
        <img src={backArrow} alt="Back" className="w-6 mr-3" />
        <Link to="/dashboard" className="text-green-600">
          Back
        </Link>
      </div>

      <div className="container flex mx-auto  justify-center items-center flex-row mt-20 mb-8">
        <div className="w-20 h-[8px] bg-[#439F46]  rounded-[24px] mr-2 cursor-pointer"></div>
        <div className="w-20 h-[8px] bg-[#439F46] opacity-20 rounded-[24px] mr-2 cursor-pointer"></div>
        <div className="w-20 h-[8px] bg-[#439F46] opacity-20 rounded-[24px] cursor-pointer"></div>
      </div>
      {/* <Link to="/dashboard" className="cursor-pointer text-green-0">
        Back
      </Link> */}
      <h1 className="text-black text-center ">Select type of service</h1>
      <div className=" container mx-auto justify-center items-center md:p-20 p-5">
        <div className="grid  grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2  gap-2 mt-5">
          {serviceTypeLists.map((serviceType, index) => (
            <ServiceType
              key={index}
              title={serviceType.title}
              image={serviceType.image}
              paragraph={serviceType.paragraph}
              route={serviceType.route}
            />
          ))}
        </div>
        <div className=" container flex justify-center items-center mx-auto  md:w-1/2 mt-3 ">
          <ServiceType
            title="Plagiarism, AI Check & Editing"
            image={plagiarism}
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            route="/"
          />
        </div>
        <button
          type="submit"
          className=" green-button mx-auto mt-10 md:w-1/4 w-1/2 p-6 flex justify-center items-center text-center"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default SelectServiceType;
