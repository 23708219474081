import React from "react";
import Layout from "../../../components/Layout";
import { useSelector } from "react-redux";
function WriterDashboard() {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <Layout>
      <div className="item-center">
        <h1 className="text-2xl text-center">
          Welcome Writer : {currentUser.fullname}
        </h1>
      </div>
      ;
    </Layout>
  );
}

export default WriterDashboard;
